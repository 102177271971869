/* ==========================================================================
   Colours
   ========================================================================== */

   $nearly-black: #000000;
   $gray: #d3d8d9;
   $light-gray: #f2f2f2;
   $dark-gray: #819397;
   $primary-color: #0F9ABE;
   $secondary-color: #7c9827;
   $button-cta-color: #0F9ABE;
   $link-color: #0F9ABE;

   /* ==========================================================================
      Fonts
      ========================================================================== */

   $primary-font:  Poppins, sans-serif;
   $secondary-font: 'Lato', sans-serif;
   $x-small-space: -.015rem;
   $small-space: 0;
   $med-space: 0.075rem;
   $space: 0.25rem;

   $font-bold: 700;
   $font-semi-bold: 600;
   $font-regular: 400;
   $font-light: 300;


   /* ==========================================================================
    images files
    ========================================================================== */
   $linkButtonIcon: url(./custom/project/img/arrow-right-green.svg);
   $starIcon: url(./custom/project/img/star-green.svg);
   $desktopLogo: url(./custom/project/img/NM-BR-logo.png);
   $mobileLogo: url(./custom/project/img/NM-BR-logo.png);

   $header-height: 167px;
